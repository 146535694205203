var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-box"},[_c('el-table',{key:_vm.index + 1,ref:"multipleTable",attrs:{"data":_vm.tableData,"max-height":"300px","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '600',
        }}},[_c('el-table-column',{attrs:{"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-radio',{attrs:{"label":scope.row},on:{"change":function($event){return _vm.chooseTable(scope.row)}},model:{value:(_vm.tableRadio),callback:function ($$v) {_vm.tableRadio=$$v},expression:"tableRadio"}},[_c('i')])]}}])}),_c('el-table-column',{attrs:{"label":"产品ID","show-overflow-tooltip":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.identification || '- -'))])]}}])}),_c('el-table-column',{attrs:{"label":"公司名称","show-overflow-tooltip":"","min-width":"239"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.companyName || '- -'))])]}}])}),(_vm.productCategory == 1)?_c('el-table-column',{attrs:{"label":"CorpID","show-overflow-tooltip":"","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.corpId || '- -'))])]}}],null,false,3444147429)}):_vm._e(),(_vm.productCategory == 2)?_c('el-table-column',{attrs:{"label":"管理员账号","show-overflow-tooltip":"","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.adminName || '- -'))])]}}],null,false,1659733070)}):_vm._e(),(
                _vm.productCategory == 2 ||
                _vm.productCategory == 4 ||
                _vm.productCategory == 12
            )?_c('el-table-column',{attrs:{"label":"域名","show-overflow-tooltip":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.domain || '- -'))])]}}],null,false,1904075974)}):_vm._e(),(_vm.productCategory != 12)?_c('el-table-column',{attrs:{"label":"账号数","show-overflow-tooltip":"","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.number || '- -'))])]}}],null,false,2489845957)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }