<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            max-height="300px"
            :key="index + 1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column width="30">
                <template slot-scope="scope">
                    <el-radio
                        v-model="tableRadio"
                        @change="chooseTable(scope.row)"
                        :label="scope.row"
                        ><i></i
                    ></el-radio>
                    <!-- :disabled="!returnShow(scope.row)" -->
                </template>
            </el-table-column>
            <el-table-column label="产品ID" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.identification || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="公司名称"
                show-overflow-tooltip
                min-width="239"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.companyName || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="CorpID"
                v-if="productCategory == 1"
                show-overflow-tooltip
                width="170"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.corpId || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="管理员账号"
                show-overflow-tooltip
                v-if="productCategory == 2"
                width="70"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="域名"
                v-if="
                    productCategory == 2 ||
                    productCategory == 4 ||
                    productCategory == 12
                "
                show-overflow-tooltip
                width="150"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.domain || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="账号数"
                v-if="productCategory != 12"
                show-overflow-tooltip
                width="70"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.number || '- -' }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column
                label="年限"
                v-if="productCategory != 12"
                show-overflow-tooltip
                width="70"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span>
                </template>
            </el-table-column> -->
        </el-table>
    </div>
</template>
<script>
import { sealList, sealGet, sealUpdate } from '@/api/seal/seal.js';

export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            auditChoice: '',
            productCategory: 0,
            tableRadio: '',
            templateUrl: this.$url.templateUrl(),
            baseUrl: this.$url.templateUrl() + '/contract/model/',
            index: '',
            productType: '',
        };
    },
    methods: {
        // 获取列表
        getData(data, productCategory, i, productType) {
            this.index = i;
            this.tableData = data;
            this.productCategory = productCategory;
            this.productType = productType;
        },
        getEditData(data, productCategory, i, dealCustomerId, productType) {
            this.index = i;
            this.tableData = data;
            this.productCategory = productCategory;
            this.productType = productType;
            let choose = data.find((item) => {
                return item.id == dealCustomerId;
            });
            if (choose) {
                this.tableRadio = choose;
                this.chooseTable(choose);
            }
        },
        chooseTable(row) {
            this.$emit('chooseTable', row, this.index);
        },
        returnShow(data) {
            if (
                this.productType == 3 &&
                new Date(data.reportExpireTime).getTime() <=
                    new Date().getTime()
            ) {
                return false;
            } else {
                return true;
            }
        },
        // returnName(data) {
        //     var str = '';
        //     data.departmentAdminDOList
        //         ? data.departmentAdminDOList.forEach((item) => {
        //               if (item.main == 1) {
        //                   str = item.departmentName;
        //               }
        //           })
        //         : '';
        //     return str;
        // },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
.info {
    color: #2370eb;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.info_box {
    position: relative;

    overflow: hidden;
    display: flex;
}
.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
.tooltip_box {
    display: flex;
    span {
        display: block;
        width: 180px;
    }
    em {
        flex: 1;
        word-break: break-all;
        font-style: normal;
    }
}
.tooltip_box + .tooltip_box {
    margin-top: 10px;
}
.sale_order {
    display: flex;
    align-items: center;
    p {
        color: #2370eb;
    }
    div {
        color: #333;
    }
    i {
        cursor: pointer;
        margin-left: 10px;
        color: #2370eb;
    }
}
.icon-xiazai-wenjianxiazai-18 {
    color: #2370eb;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}
</style>
<style lang="scss"></style>
